import React, { useState } from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import "./signup.scss"
import "./devjoin.scss"

import styled, { ThemeProvider } from "styled-components"
import Button from "../components/Button"
import { useForm } from "react-hook-form"
import Logo from "../components/Logo/Logo"
import { rem } from "../utils/styling"
import axios from "axios"

import logo from "src/images/archimydes_logo_light.svg"
import FacbookLogo from "src/images/facebook_light.svg"
import TwitterLogo from "src/images/twitter_light.svg"
import LinkedinLogo from "src/images/linkedin_light.svg"
import InstagramLogo from "src/images/instagram_light.svg"
import { darkTheme } from "../utils/themes"

import "./signup.scss"
import { getCustomerListAddress, validateEmail } from "../components/common/api"
import Comparison from "../components/Comparison"
import { splitName } from "src/utils/common"

const ModalTitle = styled.h1`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: ${rem(36)}rem;
  line-height: 1.2;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: ${rem(32)}rem;
`

const ErrorText = styled.span`
  color: red;
  font-size: 1rem;
  margin-top: 5px;
  display: inline-block;
`

function SignupPage({ location }) {
  const [showThankyou, toggleThankyou] = useState(false)
  const [invalidEmail, setInvalidEmail] = useState(false)
  const { register, handleSubmit, errors } = useForm({
    validateCriteriaMode: "all",
  })

  if (typeof window !== `undefined`) {
    window.addEventListener("message", function (e) {
      if (isCalendlyEvent(e)) {
        if (e.data.event == "calendly.event_scheduled") {
          toggleThankyou(true)
        }
      }
    })
  }

  function isCalendlyEvent(e) {
    return e.data.event && e.data.event.indexOf("calendly") === 0
  }

  const [formDetails, setFormDetails] = useState({
    name: "",
    email: "",
    phonenumber: "",
    companyname: "",
    size: "",
  })

  const handleChange = (e, field) => {
    setFormDetails({
      ...formDetails,
      [field]: e.target.value,
    })
  }

  const findTime = formData => {
    updateMailChimp(formData)
  }

  const scheduleMeeting = formData => {
    window.Calendly.initPopupWidget({
      url: "https://calendly.com/archimydes/30min",
      prefill: {
        name: formData.name,
        email: formData.email,
        customAnswers: {
          phonenumber: formData.phonenumber,
          companyname: formDetails.companyname,
          size: formDetails.size,
        },
      },
    })
  }

  const updateMailChimp = formData => {
    const href = window.location.href
    const staging =
      href.includes("s.archimydes.dev") || href.includes("localhost")

    let [firstName, lastName] = splitName(formData.name)
    let payload = {
      email: formData.email,
      firstName,
      lastName,
      phone: formData.phonenumber,
      company: formDetails.companyname,
      companySize: formDetails.size,
      environment: staging ? "Staging" : "Production",
    }

    axios
      .post(getCustomerListAddress(), payload, {
        headers: {},
      })
      .then(function (response) {
        console.log(response)
        setInvalidEmail(false)
        scheduleMeeting(formData)
        toggleThankyou(true)
      })
      .catch(function (error) {
        console.log(error)
        setInvalidEmail(true)
      })
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <link
        href="https://assets.calendly.com/assets/external/widget.css"
        rel="stylesheet"
      ></link>
      <SEO
        title="Sign up to schedule a demo"
        description="Schedule a demo. Allow us to help familiarize you with our platform and methodology."
        location={location}
      />

      <div className="subscription-page singup-container">
        <div className="page-wrapper">
          {!showThankyou && (
            <div className="subscription-form">
              <div className="logo-wrapper">
                <Link to="/">
                  <Logo />
                </Link>
              </div>
              <div className="subscription-form-content">
                <ModalTitle>Schedule a demo.</ModalTitle>
                <p>
                  Allow us to help familiarize you with our platform and
                  methodology.
                </p>
                <br />
                <form onSubmit={handleSubmit(findTime)}>
                  <div className="form-group">
                    <label className="input-label" htmlFor="">
                      Your Name
                    </label>
                    <input
                      name="name"
                      placeholder="Full Name"
                      className="input-full"
                      type="text"
                      ref={register({ required: true })}
                    />
                    {errors.name && (
                      <ErrorText>This field is required</ErrorText>
                    )}
                  </div>
                  <div className="form-group">
                    <label className="input-label" htmlFor="">
                      Company Email
                    </label>
                    <input
                      name="email"
                      placeholder="Company Email"
                      className="input-full"
                      type="text"
                      ref={register({
                        validate: value => validateEmail(value),
                      })}
                    />
                    {errors.email && (
                      <ErrorText>
                        Please use your company email to schedule time
                      </ErrorText>
                    )}
                    {invalidEmail && (
                      <ErrorText>
                        Please enter a valid company email ID
                      </ErrorText>
                    )}
                  </div>
                  {/* <div className="form-group">
                    <label className="input-label" htmlFor="">
                      Phone number
                    </label>
                    <input
                      name="phonenumber"
                      placeholder="Phone number"
                      className="input-full"
                      type="text"
                      ref={register({
                        validate: value => validatePhonenumber(value),
                      })}
                    />
                    {errors.phonenumber && (
                      <ErrorText>Enter a valid phonenumber</ErrorText>
                    )}
                  </div> */}
                  <div className="form-group">
                    <label className="input-label" htmlFor="">
                      Company name/Size
                    </label>
                    <div className="field has-addons">
                      <div className="control is-expanded">
                        <input
                          style={{
                            height: "3.5rem",
                            marginTop: "0.625rem",
                            borderBottomRightRadius: "0",
                            borderTopRightRadius: "0",
                          }}
                          className="input-full"
                          type="text"
                          placeholder="Company Name"
                          onChange={e => handleChange(e, "companyname")}
                        />
                      </div>
                      <div className="control">
                        <div
                          className="select"
                          style={{ height: "3.5rem", marginTop: "0.625rem" }}
                        >
                          <select
                            style={{
                              height: "3.5rem",
                              borderBottomRightRadius: "0.625rem",
                              borderTopRightRadius: "0.625rem",
                            }}
                            placeholder="Size"
                            onChange={e => handleChange(e, "size")}
                          >
                            <option>Size</option>
                            <option>&lt; 10</option>
                            <option>10 - 100</option>
                            <option>100 - 1000</option>
                            <option>&gt; 1000</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <Button
                      type="submit"
                      style={{
                        display: "block",
                        width: "100%",
                        height: "40px",
                        padding: "0 40px",
                      }}
                      color="secondary"
                    >
                      Find a time
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          )}

          {showThankyou && (
            <div className="column is-5 form-block">
              <Link to="/tasks">
                <img src={logo} alt="Logo" />
              </Link>
              <br />
              <br />
              <p className="is-size-2 has-text-weight-semibold">Thank you</p>
              <p className="mt-4 mb-4 is-size-6 ">
                {" "}
                Your request has been received successfully. Our community team
                member will get in touch with you shortly!
              </p>

              <button className="button is-primary mt-4 is-rounded is-expanded">
                <Link to="/tasks" style={{ color: "inherit" }}>
                  Back to site
                </Link>
              </button>
              <br />
              <br />
              <hr />

              <div className="mt-5 has-text-centered">
                <p className="is-size-5 mb-5 has-text-weight-semibold">
                  Follow us
                </p>
                <div className="columns is-mobile is-centered">
                  <div className="column is-one-fifth">
                    <Link to="https://www.facebook.com/archimydes">
                      {" "}
                      <img src={FacbookLogo} alt="Logo" />
                    </Link>
                  </div>
                  <div className="column is-one-fifth">
                    <Link to="https://twitter.com/archimydes">
                      {" "}
                      <img src={TwitterLogo} alt="Logo" />
                    </Link>
                  </div>
                  <div className="column is-one-fifth">
                    <Link to="https://www.linkedin.com/company/14778870/admin/">
                      {" "}
                      <img src={LinkedinLogo} alt="Logo" />
                    </Link>
                  </div>
                  <div className="column is-one-fifth">
                    <Link to="https://www.instagram.com/archimydes/">
                      {" "}
                      <img src={InstagramLogo} alt="Logo" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="column has-text-centered has-overflow">
            {/* <div className="has-text-centered is-size-3 has-text-weight-bold">
              Our platform works{" "}
              <span className="has-text-primary">in two ways</span>
            </div> */}
            <br />
            <Comparison />
          </div>

          {/* <div className="column has-text-centered image-block">
            <img
              src={SignupBackground}
              alt="SignupBackground"
              className="background"
            />
            <div className="text-block">
              <img src={SignupCreative} alt="SignupCreative" />
              <p className="is-size-4 has-text-weight-semibold">
                Leverage feature requests to jumpstart your development team and
                build powerful digital products.
              </p>
              <br />
              <p>
                Learn more about our services.{" "}
                <Link to="/comparison">
                  {" "}
                  <span className="has-text-primary">Click here.</span>
                </Link>
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </ThemeProvider>
  )
}

export default SignupPage
